<template>
  <v-app>
    <default-bar />

    <default-drawer />

    <default-view />

    <default-settings />

    <material-snackbar
      v-if="snackbar.model"
      v-model="snackbar.model"
      :type="snackbar.type"
      top
      :timeout="snackbar.timeout"
    >
      <div v-html="snackbar.text"></div>
    </material-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: 'DefaultLayout',

    watch: {
      $route(to, from) {
        this.closeSnackbar();
      }
    },

    computed: {
      ...mapGetters(['snackbar'])
    },

    components: {
      DefaultBar: () => import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar'
      ),
      DefaultDrawer: () => import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
      DefaultSettings: () => import(
        /* webpackChunkName: "default-settings" */
        './Settings'
      ),
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    },

    methods: {
      // Закрываем попап при смене роута (с попап ошибкой сам не закрывается)
      closeSnackbar() {
        if (this.snackbar.model) {
          this.snackbar.model = false;
        }
      }
    }
  }
</script>
